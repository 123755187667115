<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <Toaster />
</template>
<script setup lang="ts">
import Toaster from "@/components/ui/toast/Toaster";

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Váš Pověřenec` : "Váš Pověřenec";
  },
});
</script>
