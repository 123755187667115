import { default as accessYyWgAs3ZZTMeta } from "/workspace/pages/access.vue?macro=true";
import { default as analysisKKuWzyZRaJMeta } from "/workspace/pages/analysis.vue?macro=true";
import { default as forgot_45passwordd41MVLIjg6Meta } from "/workspace/pages/auth/forgot-password.vue?macro=true";
import { default as reset_45passwordCuimr8exfGMeta } from "/workspace/pages/auth/reset-password.vue?macro=true";
import { default as signin1s9amGxoDNMeta } from "/workspace/pages/auth/signin.vue?macro=true";
import { default as indexUWue15hN1FMeta } from "/workspace/pages/course/[id]/index.vue?macro=true";
import { default as testBIG0YH59OPMeta } from "/workspace/pages/course/[id]/test.vue?macro=true";
import { default as coursesmG6Y1d3GcIMeta } from "/workspace/pages/courses.vue?macro=true";
import { default as faqwxhi5zHHNsMeta } from "/workspace/pages/faq.vue?macro=true";
import { default as filesST4uVeh5bdMeta } from "/workspace/pages/files.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as newsHXfG0cbSmSMeta } from "/workspace/pages/news.vue?macro=true";
import { default as _91id_93bXp2Sdg2a2Meta } from "/workspace/pages/notice/[id].vue?macro=true";
import { default as _91slug_93E1a5vUaLkoMeta } from "/workspace/pages/request/[slug].vue?macro=true";
import { default as indexiWwT5zYgKEMeta } from "/workspace/pages/requests/index.vue?macro=true";
import { default as responsibilitiesIpDX85lDyeMeta } from "/workspace/pages/responsibilities.vue?macro=true";
import { default as settingsp6gTdPz8G5Meta } from "/workspace/pages/settings.vue?macro=true";
export default [
  {
    name: accessYyWgAs3ZZTMeta?.name ?? "access",
    path: accessYyWgAs3ZZTMeta?.path ?? "/access",
    meta: accessYyWgAs3ZZTMeta || {},
    alias: accessYyWgAs3ZZTMeta?.alias || [],
    redirect: accessYyWgAs3ZZTMeta?.redirect,
    component: () => import("/workspace/pages/access.vue").then(m => m.default || m)
  },
  {
    name: analysisKKuWzyZRaJMeta?.name ?? "analysis",
    path: analysisKKuWzyZRaJMeta?.path ?? "/analysis",
    meta: analysisKKuWzyZRaJMeta || {},
    alias: analysisKKuWzyZRaJMeta?.alias || [],
    redirect: analysisKKuWzyZRaJMeta?.redirect,
    component: () => import("/workspace/pages/analysis.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordd41MVLIjg6Meta?.name ?? "auth-forgot-password",
    path: forgot_45passwordd41MVLIjg6Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordd41MVLIjg6Meta || {},
    alias: forgot_45passwordd41MVLIjg6Meta?.alias || [],
    redirect: forgot_45passwordd41MVLIjg6Meta?.redirect,
    component: () => import("/workspace/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordCuimr8exfGMeta?.name ?? "auth-reset-password",
    path: reset_45passwordCuimr8exfGMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordCuimr8exfGMeta || {},
    alias: reset_45passwordCuimr8exfGMeta?.alias || [],
    redirect: reset_45passwordCuimr8exfGMeta?.redirect,
    component: () => import("/workspace/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signin1s9amGxoDNMeta?.name ?? "auth-signin",
    path: signin1s9amGxoDNMeta?.path ?? "/auth/signin",
    meta: signin1s9amGxoDNMeta || {},
    alias: signin1s9amGxoDNMeta?.alias || [],
    redirect: signin1s9amGxoDNMeta?.redirect,
    component: () => import("/workspace/pages/auth/signin.vue").then(m => m.default || m)
  },
  {
    name: indexUWue15hN1FMeta?.name ?? "course-id",
    path: indexUWue15hN1FMeta?.path ?? "/course/:id()",
    meta: indexUWue15hN1FMeta || {},
    alias: indexUWue15hN1FMeta?.alias || [],
    redirect: indexUWue15hN1FMeta?.redirect,
    component: () => import("/workspace/pages/course/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: testBIG0YH59OPMeta?.name ?? "course-id-test",
    path: testBIG0YH59OPMeta?.path ?? "/course/:id()/test",
    meta: testBIG0YH59OPMeta || {},
    alias: testBIG0YH59OPMeta?.alias || [],
    redirect: testBIG0YH59OPMeta?.redirect,
    component: () => import("/workspace/pages/course/[id]/test.vue").then(m => m.default || m)
  },
  {
    name: coursesmG6Y1d3GcIMeta?.name ?? "courses",
    path: coursesmG6Y1d3GcIMeta?.path ?? "/courses",
    meta: coursesmG6Y1d3GcIMeta || {},
    alias: coursesmG6Y1d3GcIMeta?.alias || [],
    redirect: coursesmG6Y1d3GcIMeta?.redirect,
    component: () => import("/workspace/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: faqwxhi5zHHNsMeta?.name ?? "faq",
    path: faqwxhi5zHHNsMeta?.path ?? "/faq",
    meta: faqwxhi5zHHNsMeta || {},
    alias: faqwxhi5zHHNsMeta?.alias || [],
    redirect: faqwxhi5zHHNsMeta?.redirect,
    component: () => import("/workspace/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: filesST4uVeh5bdMeta?.name ?? "files",
    path: filesST4uVeh5bdMeta?.path ?? "/files",
    meta: filesST4uVeh5bdMeta || {},
    alias: filesST4uVeh5bdMeta?.alias || [],
    redirect: filesST4uVeh5bdMeta?.redirect,
    component: () => import("/workspace/pages/files.vue").then(m => m.default || m)
  },
  {
    name: index9S03gOyIVcMeta?.name ?? "index",
    path: index9S03gOyIVcMeta?.path ?? "/",
    meta: index9S03gOyIVcMeta || {},
    alias: index9S03gOyIVcMeta?.alias || [],
    redirect: index9S03gOyIVcMeta?.redirect,
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: newsHXfG0cbSmSMeta?.name ?? "news",
    path: newsHXfG0cbSmSMeta?.path ?? "/news",
    meta: newsHXfG0cbSmSMeta || {},
    alias: newsHXfG0cbSmSMeta?.alias || [],
    redirect: newsHXfG0cbSmSMeta?.redirect,
    component: () => import("/workspace/pages/news.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bXp2Sdg2a2Meta?.name ?? "notice-id",
    path: _91id_93bXp2Sdg2a2Meta?.path ?? "/notice/:id()",
    meta: _91id_93bXp2Sdg2a2Meta || {},
    alias: _91id_93bXp2Sdg2a2Meta?.alias || [],
    redirect: _91id_93bXp2Sdg2a2Meta?.redirect,
    component: () => import("/workspace/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E1a5vUaLkoMeta?.name ?? "request-slug",
    path: _91slug_93E1a5vUaLkoMeta?.path ?? "/request/:slug()",
    meta: _91slug_93E1a5vUaLkoMeta || {},
    alias: _91slug_93E1a5vUaLkoMeta?.alias || [],
    redirect: _91slug_93E1a5vUaLkoMeta?.redirect,
    component: () => import("/workspace/pages/request/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexiWwT5zYgKEMeta?.name ?? "requests",
    path: indexiWwT5zYgKEMeta?.path ?? "/requests",
    meta: indexiWwT5zYgKEMeta || {},
    alias: indexiWwT5zYgKEMeta?.alias || [],
    redirect: indexiWwT5zYgKEMeta?.redirect,
    component: () => import("/workspace/pages/requests/index.vue").then(m => m.default || m)
  },
  {
    name: responsibilitiesIpDX85lDyeMeta?.name ?? "responsibilities",
    path: responsibilitiesIpDX85lDyeMeta?.path ?? "/responsibilities",
    meta: responsibilitiesIpDX85lDyeMeta || {},
    alias: responsibilitiesIpDX85lDyeMeta?.alias || [],
    redirect: responsibilitiesIpDX85lDyeMeta?.redirect,
    component: () => import("/workspace/pages/responsibilities.vue").then(m => m.default || m)
  },
  {
    name: settingsp6gTdPz8G5Meta?.name ?? "settings",
    path: settingsp6gTdPz8G5Meta?.path ?? "/settings",
    meta: settingsp6gTdPz8G5Meta || {},
    alias: settingsp6gTdPz8G5Meta?.alias || [],
    redirect: settingsp6gTdPz8G5Meta?.redirect,
    component: () => import("/workspace/pages/settings.vue").then(m => m.default || m)
  }
]