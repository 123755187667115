import type { Strapi4Error } from "@nuxtjs/strapi/dist/runtime/types/v4";
import { useToast } from "@/components/ui/toast/use-toast";
export default defineNuxtPlugin((nuxtApp) => {
  const { toast } = useToast();
  const t = nuxtApp.$i18n.t;

  nuxtApp.hook("strapi:error" as any, (e: Strapi4Error) => {
    console.log(e.error.message);
    toast({
      title: t("errors.error"),
      description: t(`errors.${e.error.message}`),
      variant: "destructive",
    });
  });
});
